
import { defineComponent } from "vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import usePayoutDetail from "@/hooks/checking/spotChecking/usePayoutDetail";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import { DataProps } from "./type";
import { Modal, message } from "ant-design-vue";
import { deletePayoutDetail } from "@/API/checking/spotChecking";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const { statInfo, query, data, column, pagination, getData, search, reset } = usePayoutDetail();
    const init = () => {
      statInfo();
      search();
    };
    // 斑马纹
    const getRowClass = useStripedRowClass<DataProps>();
    const handleDelete = (record: any) => {
      Modal.confirm({
        content: "Are you sure you want to delete this data?",
        onOk() {
          deletePayoutDetail({
            payoutRoundId: query.payoutRoundId,
            programIds: query.programIds,
            programVehicleId: record.id,
          }).then(() => {
            message.success("Successfully Delete!");
            init();
          });
        },
      });
    };
    init();
    return {
      query,
      data,
      column,
      pagination,
      isPagination: true,
      getRowClass,
      handleDelete,
      reset,
      getData,
      search,
    };
  },
  components: {
    TableWrapper,
  },
});
